import {
  Author,
  Content,
  ContentMetadata,
  CropDetails,
  SelectedMedia,
  Menu,
  MenuItem,
  WebsiteBlockDetail,
} from '@/lib/model';
import { dedupeById } from '@/utils/data/dedupeById';
import { getCroppedImage } from './getCroppedImage';

/**
 * This function try to find non webp image format for og tag specially for social content sharing
 * @param coverImage
 * @param width
 * @param height
 * @returns
 */
export const getCoverImageForOGImage = (
  coverImage?: string | SelectedMedia | null | undefined,
  width?: number,
  height?: number,
): string => {
  if ((coverImage as SelectedMedia)?.cropDetails?.length) {
    const croppedImage = getCroppedImage(
      (coverImage as SelectedMedia).cropDetails as Array<CropDetails>,
      width,
      height,
    );
    if (croppedImage?.url) return croppedImage.url;
  }
  if (coverImage) {
    return typeof coverImage === 'string'
      ? coverImage
      : coverImage?.media?.type === 'VIDEO' ||
          coverImage?.media?.type === 'AUDIO'
        ? (coverImage?.media?.originalUrl ??
          coverImage?.media?.thumbnailURL ??
          '/png/placeholder.png')
        : coverImage?.media?.originalUrl ||
          coverImage?.media?.url ||
          '/png/placeholder.png';
  }
  return '/png/placeholder.png';
};

export const getBlurImage = (
  coverImage?: string | SelectedMedia | null | undefined,
) => {
  if (coverImage) {
    return typeof coverImage === 'string'
      ? coverImage
      : coverImage?.media?.thumbnailURL || '/png/placeholder.png';
  }
  return '/png/placeholder.png';
};

export const getOptimizedImageFromCoverImage = (
  coverImage?: string | SelectedMedia | null | undefined,
  width?: number,
  height?: number,
): string => {
  if ((coverImage as SelectedMedia)?.cropDetails?.length && width && height) {
    const croppedImage = getCroppedImage(
      (coverImage as SelectedMedia).cropDetails as Array<CropDetails>,
      width,
      height,
    );
    if (croppedImage?.optimizedUrl) return croppedImage.optimizedUrl;
    else if (croppedImage?.url) return croppedImage.url;
  }
  if (coverImage) {
    return typeof coverImage === 'string'
      ? coverImage
      : coverImage?.media?.type === 'VIDEO' ||
          coverImage?.media?.type === 'AUDIO'
        ? (coverImage?.media?.optimizedThumbnailUrl ??
          coverImage?.media?.originalUrl ??
          coverImage?.media?.thumbnailURL ??
          '/png/placeholder.png')
        : coverImage?.media?.optimizedThumbnailUrl ||
          coverImage?.media?.originalUrl ||
          coverImage?.media?.url ||
          '/png/placeholder.png';
  }
  return '/png/placeholder.png';
};

export const getImageFromCoverImage = (
  coverImage?: string | SelectedMedia | null | undefined,
): string => {
  if (coverImage) {
    return typeof coverImage === 'string'
      ? coverImage
      : coverImage?.media?.type === 'VIDEO' ||
          coverImage?.media?.type === 'AUDIO'
        ? (coverImage?.media?.thumbnailUrl ??
          coverImage?.media?.url ??
          '/png/placeholder.png')
        : coverImage?.media?.url || '/png/placeholder.png';
  }
  return '/png/placeholder.png';
};

export const getMediaIconType = (content?: Content) => {
  const isVideo = [
    'dailyVideo',
    'videoCard',
    'videoPodcast',
    'tvContent',
  ].includes(content?.subType ?? '');
  const isAudio = [
    'podcastEpisode',
    'dailyNewsBriefEpisode',
    'featureEpisode',
  ].includes(content?.subType ?? '');
  return {
    isVideo,
    isAudio,
  };
};

export function getCoverImageCaption(coverImage: SelectedMedia) {
  const source = coverImage?.source;
  const rawCaption = coverImage?.caption || '';
  const lowerCaseCaption = rawCaption?.toLowerCase();

  const hasSourceInCaption =
    lowerCaseCaption.includes('/') &&
    lowerCaseCaption.includes(source?.title?.toLowerCase() || '');

  if (hasSourceInCaption || !source?.title) {
    return rawCaption;
  }
  return `${rawCaption} / ${source?.title}`;
}
export const getContentMetadata = (content?: Content) => {
  const mobileContentMetadata = getContentMetadataByPlatform('mobile', content);
  const desktopContentMetadata = getContentMetadataByPlatform(
    'desktop',
    content,
  );
  return { mobileContentMetadata, desktopContentMetadata };
};

export const getContentMetadataByPlatform = (
  type: ContentMetadata['platform'],
  content?: Content,
): ContentMetadata => {
  const websiteBlockDetails =
    content?.websiteBlockDetails as WebsiteBlockDetail;
  const contentMetadataList =
    websiteBlockDetails?.contentMetadata as ContentMetadata[];

  // find contentMetadata by platform
  const contentMetadata = contentMetadataList?.find(item => {
    return item.platform === type;
  });
  const coverImage = content?.coverImage as SelectedMedia;
  const rootMedia = content?.media as SelectedMedia;
  const wPlusMedias = contentMetadata?.medias?.filter(m => !!m?.media) || [];
  // w+ medias or media or cover image
  let medias = wPlusMedias?.length
    ? wPlusMedias
    : rootMedia || content?.coverImage
      ? [
          {
            media: rootMedia
              ? {
                  ...rootMedia,
                  media: {
                    ...rootMedia.media,
                    optimizedThumbnailUrl:
                      getOptimizedImageFromCoverImage(coverImage),
                  },
                }
              : coverImage,
          },
        ]
      : [];

  // single w+ video
  if (wPlusMedias?.length === 1 && coverImage) {
    medias = wPlusMedias.map(item => {
      if (typeof item.media === 'string' || !item.media) return item;
      const media = { ...item.media };
      if (
        item.media?.media?.type === 'VIDEO' ||
        media?.media?.type === 'AUDIO'
      ) {
        item.media = {
          media: {
            ...media?.media,
            optimizedThumbnailUrl: getOptimizedImageFromCoverImage(coverImage),
          },
        };
      }
      return item;
    });
  }
  const author = content?.author as Author;
  return {
    platform: type,
    medias: medias,
    authorAvatarURL: author?.profileUrl ?? undefined,
    description: contentMetadata?.description ?? content?.description,
    authorName: author?.name ?? undefined,
    liveStream: contentMetadata?.liveStream,
    authorId: author?.id ?? undefined,
    otherAuthors: content?.otherAuthors as Author[],
    // liveStream: item?.title.includes('International law')
    //   ? {
    //       streamUrl: 'https://tv-trtworld.medya.trt.com.tr/master.m3u8',
    //     }
    //   : item?.liveStream,
    title: contentMetadata?.title ?? content?.title,
    id: contentMetadata?.id,
    duration: content?.duration,
    slug: content?.slug,
  } as ContentMetadata;
};

export const getNavigatorConnection = (): any => {
  if (
    'connection' in navigator ||
    'mozConnection' in navigator ||
    'webkitConnection' in navigator
  ) {
    return 'connection' in navigator
      ? navigator.connection
      : 'mozConnection' in navigator
        ? navigator.mozConnection
        : 'webkitConnection' in navigator
          ? navigator.webkitConnection
          : null;
  } else {
    return;
  }
};

export const internetAvailableForVideo = () => {
  if (typeof window === 'undefined') return false;
  const connection = getNavigatorConnection();
  if (!connection) return true;
  return true;
  // return !(
  //   connection?.effectiveType === 'slow-2g' ||
  //   connection?.effectiveType === '2g' ||
  //   connection?.effectiveType === '3g'
  // );
};

export const getValidMenuItem = (menu: MenuItem) => {
  return (
    (menu.linkType === 'custom' && menu.url) ||
    (menu.linkType === 'live_coverage' && menu.liveCoverage?.id) ||
    (menu.linkType === 'content' && menu.content) ||
    (menu.linkType === 'topic' && menu?.slug) ||
    (menu.linkType === 'page' && menu.page?.slug) ||
    menu.slug
  );
};

export function getValidMenuItemsFromMenu(menu?: Menu) {
  const menuItems = dedupeById(
    [
      ...(menu?.primaryMenuItems || []),
      ...(menu?.secondaryMenuItems || []),
    ]?.map(item => item as MenuItem),
  );

  return (
    menuItems.filter((item: MenuItem) => {
      if (getValidMenuItem(item)) {
        return item;
      }
    }) ?? []
  );
}

function pad(input: number) {
  return input < 10 ? '0' + input : input;
}

export function getContentDurationString(duration?: string | number) {
  if (!duration) return;
  if (
    typeof duration === 'string' &&
    (duration as string).includes(':')
    // duration !== '00:00'
  ) {
    return duration;
  }
  if (duration && !isNaN(+duration)) {
    return `${pad(Math.floor((+duration % 3600) / 60))}:${pad(Math.floor(+duration % 60))}`;
  }
}
